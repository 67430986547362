import React from "react"
import { graphql } from "gatsby"

// components
import Image from "../components/utils/image"
import Layout from "../components/layout/layout"
import Footer from "../components/layout/footer"
import SEO from "../components/helmet/seo"
import Back from "../components/partials/svg/back"

const ProjectPage = ({ data }) => {
  // Graphql data
  const project = data.markdownRemark

  return (
    <Layout mainColor="#000814" styleClass="disabled-footer project-templates">
      <SEO title={project.frontmatter.title} description={project.frontmatter.description} />
      <div id="project-templates">
        <div className="main-page-side top">
          <Back url="/portfolio" styleClass="back-link--black" text="retour" />
          <h1>{project.frontmatter.title}</h1>
          <p className="project-date">{project.frontmatter.date} &#183; {project.frontmatter.type}</p>
          <a style={{backgroundColor: project.frontmatter.color, borderColor: project.frontmatter.color}} className="project-redirect" href={project.frontmatter.redirect}>voir le projet</a>
          <div className="project-info"
            dangerouslySetInnerHTML={{ __html: project.html }} />
          <Footer />
        </div>
        <div className="main-page-side bottom">
          <Image 
            alt={project.frontmatter.title} 
            filename={project.frontmatter.mockup}
            style={{width: "100%", height: "100%" , position: "absolute", zIndex: `-5`}}
            imgStyle={{objectPosition: project.frontmatter.mockpos}}
          />
          <section className="main-page-projects">
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default ProjectPage

export const pageQuery = graphql`
  query ProjectBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        thumb
        title
        mockup
        mockpos
        type
        color
        redirect
        description
        date(formatString: "MMMM YYYY", locale: "fr")
      }
      fields {
        slug
      }
    }
  }
`